.contact_button_container {
  display: block;
}

.contact_button {
  font-size: 1.4em;
}

.contact_button:disabled {
  background-color: #ccc;
}
